<template>
  <div>
      <v-chip id="tmc-report-chip"
        class="ma-2 elevation-8"
        color="primary"
        :disabled="isDisabled || !isValid"
        @click.stop="openReport"
      >
        <v-icon>{{icons.mdiNote}}</v-icon>
        <span class="ml-4">{{caption}}</span>
      </v-chip>
      <div v-if="hasErrors" class="">
        <v-card>
          <v-toolbar
            color="red darken-3"
            dark
            dense
            flat
            rounded
          >
            <v-toolbar-title class="body-2">
              <v-icon>{{icons.mdiAlert}}</v-icon>
              Please check your inputs
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <ul>
              <li v-for="error in errors" :key="error">
                {{error}}
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </div>
  </div>
</template>

<script>
import {
  mdiNote,
  mdiAlert,
} from '@mdi/js';
import { clients } from '../../util/clients';
import { validateQueryString } from '../../views/OD/report-util';

const { backendRest } = clients.direct;

export default {
  props: {
    to: {
      type: String,
      required: true,
    },
    queryString: {
      type: String,
      required: true,
    },
    queryStringForSecondReport: {
      type: String,
      required: true,
    },
    caption: {
      type: String,
      default: 'Link to report',
    },
    isValid: {
      type: Boolean,
      default: false,
    },
    dualReport: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    icons: {
      mdiNote,
      mdiAlert,
    },
    hasErrors: false,
    errors: [],
  }),
  computed: {
    isDisabled() {
      return this.to === '';
    },
  },
  methods: {
    checkForErrors(queryString) {
      const { hasErrors, errors } = validateQueryString(this.to, queryString);
      this.hasErrors = hasErrors;
      this.errors = errors;
      return hasErrors;
    },
    openReport() {
      this.redirectToReport(this.queryString);
      if (this.dualReport) {
        this.redirectToReport(this.queryStringForSecondReport);
      }
    },
    redirectToReport(queryString) {
      const toUrl = `${backendRest.defaults.baseURL}/${this.to}${queryString}`;
      if (!this.checkForErrors(queryString)) {
        window.open(toUrl);
      }
    },
  },
};
</script>
