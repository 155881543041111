<template>
  <div>
    <v-container >
      <v-row class="elevation-1">
        <v-col cols="6" lg="12">
          <v-select id="tmc-report-type" dense
            class="od-type-of-report-select"
            v-model="reportConfig"
            :items="reports"
            item-text="description"
            item-value="config"
            label="Type of report"
          ></v-select>
        </v-col>
        <v-col cols="6" lg="12"
        v-if="reportConfig.filters.showTransTypes">
          <v-select id="odReportsDescriptionSelect"
            class="od-reports-description-select"
            v-model="criteria.logicalTypes"
            :items="logicalTransTypes"
            item-text="description"
            item-value="name"
            multiple
            label="Transactions to include"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="elevation-1">
        <v-col  cols="8" lg="4"
        v-if="reportConfig.filters.showDepartments">
          <v-select id="odReportsByDepartmentSelect"
            class="od-reports-by-department-select"
            v-model="criteria.departments"
            :items="departments"
            item-text="caption"
            item-value="id"
            label="By department"
            multiple
          >
            <template v-slot:selection="{ item, index }"
            v-if="reportConfig.filters.showDepartments">
              <v-chip v-if="index === 0">
                <span>{{ item.caption }}</span>
              </v-chip>
              <span
                v-if="index === 1"
                class="grey--text text-caption"
              >
                (+{{ criteria.departments.length - 1 }} others)
              </span>
            </template>
            <template v-slot:prepend-item
            v-if="reportConfig.filters.showDepartments">
              <v-list-item
                ripple
                @click="toggle"
              >
                <v-list-item-action>
                  <v-icon :color="criteria.departments.length > 0 ? 'indigo darken-4' : ''">
                    {{ icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Select All
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
        </v-col>
        <v-col cols="4" lg="4"
        v-if="reportConfig.filters.showWkst">
          <v-select id="odReportsByWkstSelect"
            class="od-reports-by-wkst-select"
            v-model="criteria.wkst"
            :items="wkstList"
            item-text="wkstInt"
            item-value="_id"
            label="By Workstation"
            multiple
          >
            <template v-slot:selection="{ item, index }"
            v-if="reportConfig.filters.showWkst">
              <v-chip v-if="index === 0">
                <span>{{ item.wkstInt }}</span>
              </v-chip>
              <span
                v-if="index === 1"
                class="grey--text text-caption"
              >
                (+{{ criteria.wkst.length - 1 }} others)
              </span>
            </template>
            <template v-slot:prepend-item
            v-if="reportConfig.filters.showWkst">
              <v-list-item
                ripple
                @click="toggleWkst"
              >
                <v-list-item-action>
                  <v-icon :color="criteria.wkst.length > 0 ? 'indigo darken-4' : ''">
                    {{ wkstIcon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Select All
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="reportConfig.filters.showOfficers">
        <v-col>
          <v-select id="odReoprtByDeptOfficerSelect"
            class="od-reoprt-by-dept-officer-select"
            v-model="criteria.officer"
            :items="officers"
            label="By dept officer"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="reportConfig.filters.showFromToDates">
          <v-col id="tmc-report-from-date">
            <ClarionDateControl class="od-report-from-date"
              v-model="criteria.fromDate"
              :isRequired="true" label="From Date" />
          </v-col>
          <v-col>
            <ClarionDateControl id="odReportToDate"
              class="od-report-to-date"
              v-model="criteria.toDate"
              :isRequired="true" label="To Date" />
          </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="reportConfig.filters.showFromToFinalizedDates">
        <v-col>
            <ClarionDateControl id="odReportPaidOrCancelDate"
              class="od-report-paid-or-cancel-date"
              v-model="criteria.fromFinalizedDate"
              :isRequired="true" label="From (paid/cancel) Date" />
          </v-col>
          <v-col>
            <ClarionDateControl id="odReportToFinalDate"
              class="od-report-to-final-date"
              v-model="criteria.toFinalizedDate"
              :isRequired="true" label="To Date" />
          </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="reportConfig.filters.showFiscalYears">
        <v-select class="ma-4"
          id="odReportByFYSelect"
          v-model="criteria.fy"
          :items="odStatsFY"
          item-text="name"
          item-value="year"
          label="By Fiscal Year"
          @change="fychanged"
        ></v-select>
      </v-row>
      <v-row class="elevation-1"
        v-if="reportConfig.filters.showFileFormat">
        <v-select class="ma-4"
          id="odReportFileFormatSelect"
          v-model="criteria.format"
          :items="reportFormats"
          label="Report file format"
        ></v-select>
      </v-row>
      <v-row class="elevation-1"
        v-if="reportConfig.filters.showCancelledByStatute">
        <v-col cols="3"
          id="odReportCancelledByStatute">
          <v-checkbox
            v-model="criteria.isCancelledByStatute"
            label="Cancelled By Statute  Only?">
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <ReportChip id="odReportViewReportChip"
        class="od-report-view-report-chip"
        :to="reportConfig.route"
        :queryString="queryString"
        :queryStringForSecondReport="queryStringForSecondReport"
        :isValid="validateDate"
        :dualReport="isDualReport"
        caption="View Report"
        />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  mdiSelectAll,
  mdiCloseBox,
  mdiCheckboxBlankOutline,
  mdiMinusBox,
} from '@mdi/js';

import { mapState, mapActions, mapGetters } from 'vuex';
import ReportChip from '../../components/reports/ReportChip.vue';
import ClarionDateControl from '../../components/common/ClarionDateControl.vue';

import { toFiscalYear, toShortDate } from '../../util/shared/tmc-global';
import { isDate } from '../../util/shared/vue-global';
import { reportList, queryStringCalc } from './report-util';

export default {
  name: 'Reports',
  components: {
    ReportChip,
    ClarionDateControl,
  },
  data: () => ({
    icons: {
      mdiSelectAll,
      mdiCloseBox,
      mdiCheckboxBlankOutline,
      mdiMinusBox,
    },
    criteria: {
      format: undefined,
      logicalTypes: [
        'Beginning Balance',
        'Deposit',
        'Voucher',
        'Cancelled voucher',
        'Correction',
      ],
      departments: [],
      officer: undefined,
      fromDate: undefined,
      toDate: undefined,
      fromFinalizedDate: undefined,
      toFinalizedDate: undefined,
      isCancelledByStatute: false,
      fy: {},
      sortBy: [],
      wkst: [],
    },
    wkstList: [],
    token: undefined,
    reportConfig: {
      route: '',
      filters: {
        showDepartments: false,
        showFromToDates: false,
        showTransTypes: false,
      },
      reportType: [],
    },
    fiscalYears: [{ range: {}, name: '(all years)' }],
  }),
  async created() {
    const { jwt } = await this.reAuth();
    this.token = `token=${jwt}`;
    this.loadAndSelectDepartments();
    this.loadFiscalYearAndSetDefault();
    this.loadWorkStationNumbers();

    // populate defaults
    this.criteria.format = 'pdf';
    this.criteria.fromDate = toShortDate(this.todaysDate);
    this.criteria.toDate = toShortDate(this.todaysDate);
    this.criteria.fromFinalizedDate = toShortDate(this.todaysDate);
    this.criteria.toFinalizedDate = toShortDate(this.todaysDate);
  },
  computed: {
    ...mapState({
      departments: (state) => state.OD.departments,
      officers: (state) => {
        const officers = state.OD.departments.map(
          (dept) => dept.officer,
        );
        return officers;
      },
      odStatsFY: (state) => state.OD.odStatsFY || [],
      devSettingItem: (state) => state.SystemConfig.devSettingItem || {},
    }),
    ...mapGetters([
      'todaysDate',
    ]),
    reportFormats() {
      return ['pdf', 'csv'];
    },
    // taking a more 'logical' approach to transaction types w/ reports,
    // so we can treat cancels as something separate from vouchers
    logicalTransTypes() {
      return [
        'Beginning Balance',
        'Deposit',
        'Voucher',
        'Cancelled voucher',
        'Correction',
      ];
    },
    reports() {
      return [
        ...reportList(),
      ];
    },
    queryString() {
      return queryStringCalc(
        this.reportConfig, this.criteria, this.token,
        { sencondReport: false, devSetting: this.devSettingItem },
      );
    },
    queryStringForSecondReport() {
      return queryStringCalc(
        this.reportConfig, this.criteria, this.token,
        { sencondReport: true, devSetting: this.devSettingItem },
      );
    },
    isAllDeptsSelected() {
      return this.criteria.departments.length === this.departments.length;
    },
    isSomeDeptsSelected() {
      return this.criteria.departments.length > 0 && !this.isAllDeptsSelected;
    },
    icon() {
      if (this.isAllDeptsSelected) return this.icons.mdiCloseBox;
      if (this.isSomeDeptsSelected) return this.icons.mdiMinusBox;
      return this.icons.mdiCheckboxBlankOutline;
    },
    wkstIcon() {
      if (this.isAllWkstSelected) return this.icons.mdiCloseBox;
      if (this.isSomeWkstSelected) return this.icons.mdiMinusBox;
      return this.icons.mdiCheckboxBlankOutline;
    },
    isAllWkstSelected() {
      return this.criteria.wkst.length === this.wkstList.length;
    },
    isSomeWkstSelected() {
      return this.criteria.wkst.length > 0 && !this.isAllWkstSelected;
    },
    validateDate() {
      if (isDate(this.criteria.fromDate)
      && isDate(this.criteria.toDate)
      && (new Date(this.criteria.fromDate)) <= (new Date(this.criteria.toDate))) {
        return true;
      }
      return false;
    },
    isDualReport() {
      if (this.reportConfig.reportType && this.reportConfig.reportType.length >= 2) return true;
      return false;
    },
  },
  methods: {
    ...mapActions({
      reAuth: 'reAuth',
      loadDepartments: 'OD/loadDepartments',
    }),
    ...mapActions('OD', [
      'loadFiscalYears',
      'loadWkstList',
    ]),
    async loadAndSelectDepartments() {
      await this.loadDepartments();
      this.selectAllDepts();
    },
    loadFiscalYearAndSetDefault() {
      this.loadFiscalYears().then(() => {
        if ((this.odStatsFY || []).length > 0) {
          this.criteria.fy = (this.odStatsFY[this.odStatsFY.length - 1] || {}).year;
        }
      }).catch((e) => {
        this.$store.dispatch('logException', e);
      });
    },
    fychanged() {
      this.loadWorkStationNumbers(this.criteria.fy);
    },
    async loadWorkStationNumbers(fy) {
      const fiscalYear = fy || toFiscalYear(new Date());
      this.wkstList = await this.loadWkstList({ fiscalYear }) || [];
      this.selectAllWkst();
      return this.wkstList;
    },
    selectAllDepts() {
      this.criteria.departments = [...this.departments.map((d) => d.id)];
    },
    selectAllWkst() {
      this.criteria.wkst = [...this.wkstList.map((wkstNumber) => wkstNumber._id)];
    },
    toggle() {
      this.$nextTick(() => {
        if (this.isAllDeptsSelected) {
          this.criteria.departments = [];
        } else {
          this.selectAllDepts();
        }
      });
    },
    toggleWkst() {
      this.$nextTick(() => {
        if (this.isAllWkstSelected) {
          this.criteria.wkst = [];
        } else {
          this.selectAllWkst();
        }
      });
    },
  },
};

</script>
