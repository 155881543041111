export const queryStringCalc = (reportConfig, criteria, token, { sencondReport, devSetting }) => {
  let departments;
  if (reportConfig.filters.showDepartments && criteria.departments.length > 0) {
    departments = criteria.departments.join(',');
  }
  departments = departments ? `departments=${departments}` : '';

  let officer;
  if (reportConfig.filters.showOfficers
    && (criteria.officer || '').length > 0) {
    officer = criteria.officer;
  }
  officer = officer ? `officer=${officer}` : '';

  let format;
  if (reportConfig.filters.showFileFormat
    && (criteria.format || '').length > 0) {
    format = criteria.format;
  }
  format = format ? `format=${format}` : '';

  let wkst;
  if (reportConfig.filters.showWkst && criteria.wkst.length > 0) {
    wkst = criteria.wkst.join(',');
  }
  wkst = wkst ? `wkst=${wkst}` : '';

  // vuetify defaults to YYYY-MM-DD format
  let fromDate;
  if (reportConfig.filters.showFromToDates && criteria.fromDate) {
    fromDate = criteria.fromDate;
  }
  fromDate = fromDate ? `fromDate=${fromDate}` : '';

  let toDate;
  if (reportConfig.filters.showFromToDates && criteria.toDate) {
    toDate = criteria.toDate;
  }
  toDate = toDate ? `toDate=${toDate}` : '';

  let fromFinalizedDate;
  if (reportConfig.filters.showFromToFinalizedDates && criteria.fromFinalizedDate) {
    fromFinalizedDate = criteria.fromFinalizedDate;
  }
  fromFinalizedDate = fromFinalizedDate ? `fromFinalizedDate=${fromFinalizedDate}` : '';

  let toFinalizedDate;
  if (reportConfig.filters.showFromToFinalizedDates && criteria.toFinalizedDate) {
    toFinalizedDate = criteria.toFinalizedDate;
  }
  toFinalizedDate = toFinalizedDate ? `toFinalizedDate=${toFinalizedDate}` : '';

  let fy;
  if (reportConfig.filters.showFiscalYears && criteria.fy) {
    fy = criteria.fy;
  }
  fy = fy ? `fy=${fy}` : '';

  let isCancelledByStatute;
  if (reportConfig.filters.showCancelledByStatute) {
    isCancelledByStatute = `isCancelledByStatute=${criteria.isCancelledByStatute}`;
  }

  let logicalTypes;
  if (reportConfig.filters.showTransTypes && criteria.logicalTypes.length > 0) {
    logicalTypes = criteria.logicalTypes.join(',');
  }
  logicalTypes = logicalTypes ? `logicalTypes=${logicalTypes}` : '';

  let reportType;
  if (reportConfig.reportType && reportConfig.reportType.length > 0) {
    if (sencondReport) {
      [, reportType] = reportConfig.reportType;
    } else {
      [reportType] = reportConfig.reportType;
    }
    reportType = `reportType=${reportType}`;
  }

  /* For testcafe */
  let isTestCafe;
  if (devSetting && devSetting.isTestEnvironment) {
    isTestCafe = `isTestCafe=${1}`;
  }

  const params = [
    logicalTypes,
    wkst,
    isCancelledByStatute,
    fromDate,
    toDate,
    format,
    fromFinalizedDate,
    toFinalizedDate,
    fy,
    officer,
    departments,
    token,
    reportType,
    isTestCafe,
  ];

  const paramFilter = (p) => p !== undefined && p !== '';
  let queryString = params.filter(paramFilter).join('&');
  queryString = (queryString.length > 0) ? `?${queryString}` : '';

  return queryString;
};

export const validateQueryString = (to, queryString) => {
  let hasErrors = false;
  const errors = [];
  const params = queryString.split('&');
  switch (to) {
    case 'sai140': {
      const officerParam = params.filter((param) => param.indexOf('officer') !== -1);
      if (officerParam.length === 0 || officerParam[0] === 'officer=') {
        hasErrors = true;
        errors.push('Select an officer');
      }
      break;
    }
    default:
  }
  return {
    hasErrors,
    errors,
  };
};

export const reportList = () => {
  const list = [
    {
      description: 'Daily Edit',
      config: {
        route: 'dailyedit',
        filters: {
          showDepartments: true,
          showFromToDates: true,
          showTransTypes: true,
          showFiscalYears: true,
        },
      },
    },
    {
      description: 'Deposit Register',
      config: {
        route: 'depositregister',
        filters: {
          showDepartments: true,
          showFromToDates: true,
          showFiscalYears: true,
        },
      },
    },
    {
      description: 'Deposit Register By Workstation',
      config: {
        route: 'od/depositregister-by-wkst',
        filters: {
          showDepartments: true,
          showFromToDates: true,
          showFiscalYears: true,
          showWkst: true,
        },
      },
    },
    {
      description: 'Voucher Register',
      config: {
        route: 'voucherregister',
        filters: {
          showDepartments: true,
          showFromToDates: true,
          showFiscalYears: true,
        },
      },
    },
    {
      description: 'Cancelled Voucher Register',
      config: {
        route: 'od/cancelled-vouchers',
        filters: {
          showDepartments: true,
          showFromToDates: true,
          showFiscalYears: true,
          showCancelledByStatute: true,
        },
      },
    },
    {
      description: 'Outstanding Vouchers',
      config: {
        route: 'outstandingvouchers',
        filters: {
          showDepartments: true,
          showFromToDates: true,
        },
      },
    },
    {
      description: 'Outstanding Vouchers By Department',
      config: {
        route: 'outstandingvouchersbydept',
        filters: {
          showDepartments: true,
          showFromToDates: true,
        },
      },
    },
    {
      description: 'Outstanding CC & EFT Deposits',
      config: {
        route: 'outstandingccdeposits',
        filters: {
          showDepartments: true,
          showFromToDates: true,
        },
      },
    },
    {
      description: 'Cancellation Letters',
      config: {
        route: 'cancelletters',
        filters: {
          showDepartments: true,
          showFromToFinalizedDates: true,
          showFiscalYears: true,
        },
      },
    },
    {
      description: 'FY Department Balances',
      config: {
        route: 'deptbalances',
        filters: {
          showDepartments: true,
          showFiscalYears: true,
        },
      },
    },
    {
      description: 'Department Control Balances',
      config: {
        route: 'deptcontrols',
        filters: {
          showDepartments: true,
          showFromToDates: true,
          showFileFormat: true,
          showFiscalYears: true,
        },
      },
    },
    {
      description: 'Vouchers Redeemed by Dept',
      config: {
        route: 'vouchredeembydept',
        filters: {
          showDepartments: true,
          showFromToDates: true,
        },
      },
    },
    {
      description: 'Vouchers Redeemed (by Reg and FY)',
      config: {
        route: 'vouchredeemed',
        filters: {
          showDepartments: true,
          showFromToDates: true,
        },
      },
    },
    {
      description: 'Monthly Officers (SAI 140)',
      config: {
        route: 'sai140',
        filters: {
          showOfficers: true,
          showFromToDates: true,
          showFiscalYears: true,
        },
      },
    },
    {
      description: 'General Ledger',
      config: {
        route: 'generalledger',
        filters: {
          showDepartments: true,
          showFromToDates: true,
          showFiscalYears: true,
        },
      },
    },
    {
      description: 'Bank Deposit',
      config: {
        route: 'bankdeposit',
        filters: {
          showDepartments: true,
          showFromToDates: true,
        },
        reportType: ['detailed', 'summary'],
      },
    },
    {
      description: 'Trust Receipts by Date',
      config: {
        route: 'trustreceiptreport',
        filters: {
          showDepartments: true,
          showFromToDates: true,
        },
      },
    },
  ];
  return list;
};

export default {
  reportList,
  queryStringCalc,
  validateQueryString,
};
